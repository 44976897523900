import Vue from 'vue'
import { i18n } from 'vue-lang-router';

export default function createToast(type, title, message, icon) {
  const Toast = {
    template: `
    <div class="toast" :class="['toast--'+type, { 'toast--hidden' : !isVisible }]">
      <div class="toast__header">
        <div class="toast__iconWrapper">
            <span class="material-icons-outlined toast__icon">{{ icon || 'info' }}</span>
        </div>

        <strong class="toast__title">{{ title }}</strong>

        <a class="toast__close" @click="closeToast"><span class="material-icons-outlined">close</span></a>
      </div>
      <div v-if="message" class="toast__body">{{ message }}</div>
      <ButtonComponent style="margin: 20px 0 0 40px" :caption="buttonCaption" @click="closeToast" />
    </div>`,
    props: ['type', 'title', 'message', 'icon'],

    data() {
        return {
            isVisible: true
        }
    },

    computed: {
        buttonCaption() {
          return i18n.t(`components.Toast.button`) 
        }
    },

    mounted() {
        setTimeout(() => { this.isVisible = false }, 9_000);
        setTimeout(() => { this.$el.parentNode.removeChild(this.$el) }, 10_000);
    },

    methods: {
        closeToast() {
            this.$el.parentNode.removeChild(this.$el);
        }
    }
  };

  const ToastComponent = Vue.extend(Toast);
  const t = new ToastComponent({
    propsData: {
      message, title, type, icon
    }
  }).$mount();

  document.getElementsByClassName('base-layout__toasts')[0].appendChild(t.$el)
}