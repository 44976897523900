import api from './_httpClient'

import article from "./api-services/article";
import category from "./api-services/category";
import feedback from "./api-services/feedback";
import filters from "./api-services/filters";
import menu from "./api-services/menu";
import search from "./api-services/search";
import home from "./api-services/home";

const global = {
    languages() {
        return api.get(`/api/languages`)
    },

    concept() {
        return api.get(`/api/concept`)
    }
}

export default {
    global,
    article,
    category,
    feedback,
    filters,
    menu,
    search,
    home
}