import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from "axios";
import VueAxios from 'vue-axios'
import { i18n } from 'vue-lang-router';

import mixins from './helpers/mixins'
import createToast from "@/helpers/toast"

/* style */
import '@/assets/scss/app.scss';
import 'material-icons/iconfont/material-icons.css';
import "vue-select/dist/vue-select.css";
import 'vue-slick-carousel/dist/vue-slick-carousel.css'
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'

/* base components */
import vSelect from "vue-select";
import Paginate from 'vuejs-paginate'
import ButtonComponent from "@/components/ButtonComponent"
import Breads from "@/components/Breads"
import Preloader from "@/components/Preloader"
import EmptyList from "@/components/EmptyList"
import VueSlickCarousel from 'vue-slick-carousel'

Vue.component("v-select", vSelect);
Vue.component('ButtonComponent', ButtonComponent)
Vue.component('Breads', Breads)
Vue.component('Preloader', Preloader)
Vue.component('EmptyList', EmptyList)
Vue.component('VueSlickCarousel', VueSlickCarousel)
Vue.component('Paginate', Paginate)

Vue.mixin(mixins);

Vue.prototype.toast = {
  success(title, message = null, icon = null) {
      createToast('success', title, message, icon)
  },
  info(title, message = null, icon = null) {
      createToast('info', title, message, icon)
  },
  error(title, message = null, icon = null) {
      createToast('error', title, message, icon)
  }
}

Vue.use(VueAxios, axios)
Vue.config.productionTip = false

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
