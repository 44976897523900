import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    resultsView: 'grid'
  },
  getters: {
    getResultsView(state) {
      return state.resultsView
    }
  },
  mutations: {
    setResultsView(state, v) {
      state.resultsView = v
    }
  },
  actions: {
    setResultsView({ commit }, v) {
      commit('setResultsView', v)
    }
  },
})
