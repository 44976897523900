import axios from 'axios';
import { i18n } from 'vue-lang-router';

/** Creating the instance for axios */
const httpClient = axios.create();

/** Adding the response interceptors */
httpClient.interceptors.response.use(
    response => {
       return response.data;
    },
    error => {
       throw error;
    }
);

httpClient.interceptors.request.use(config => {
    config.headers['X-CSRF-TOKEN'] = '';
    config.headers['Accept-Language'] = i18n.locale;
    return config;
})

export default httpClient;
