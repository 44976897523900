<template>
    <div class="breads">
        <localized-link :to="{ name: 'Landing' }" class="breads__link">{{ $t('components.Breads.main') }}</localized-link>
        <div v-for="(link, index) in links" :key="index">
            <span class="breads__span">></span>
            <localized-link :to="getLink(link.route)" class="breads__link">{{ link.text }}</localized-link>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        links: {
            required: false,
            type: Array,
            default: () => []
        }
    },

    methods: {
        getLink(r) {
            return r.startsWith('/') ? { path: r } : { name: r }
        }
    }
}
</script>

<style lang="scss" scoped>
    .breads {
        display: flex;
        align-items: center;
        flex-wrap: wrap;

        &__link {
            color: $black;
            font-size: 18px;
            font-family: $ff-primary;

            &:hover {
                color: $blue;
                text-decoration: underline;
            }
        }

        &__span {
            margin: 0 5px;
        }
    }
</style>