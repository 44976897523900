import Vue from 'vue'
import LangRouter from 'vue-lang-router';
import translations from '@/lang/translations';
import { i18n } from 'vue-lang-router';

Vue.use(LangRouter, {
    defaultLanguage: 'ua',
    translations,
});
  
const router = new LangRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes : [
        {
            path: '/',
            name: 'Landing',
            component: () => import('@/pages/Landing.vue'),
            meta: {
                title: 'Landing'
            },
        },

        {
            path: '/feedback',
            name: 'Feedback',
            component: () => import('@/pages/Feedback.vue'),
            meta: {
                title: 'Feedback'
            },
        },

        {
            path: '/concept',
            name: 'Concept',
            component: () => import('@/pages/Concept.vue'),
            meta: {
                title: 'Concept'
            },
        },

        {
            path: '/category/:uri',
            name: 'Category',
            component: () => import('@/pages/Category.vue'),
            meta: {
                title: 'Category'
            },
        },

        {
            path: '/search',
            name: 'Search',
            component: () => import('@/pages/Search.vue'),
            meta: {
                title: 'Search'
            },
        },

        {
            path: '/article/:name',
            name: 'Article',
            component: () => import('@/pages/Article.vue'),
            meta: {
                title: 'Article'
            },
        },

        {
            path: '/chapter/:name',
            name: 'Chapter',
            component: () => import('@/pages/Search.vue'),
            meta: {
                title: 'Chapter'
            },
        },

        {
            path: '/3d-tour/:uri',
            name: 'Tour',
            component: () => import('@/pages/Tour.vue'),
            meta: {
                title: 'Tour'
            },
        },
    ]
})

router.beforeEach((to, from, next) => {
    if (to?.meta?.title) {
        document.title = i18n.t(`router.${to.meta.title}`) + ' | Г.С.Сковорода';
    }
    next();
})


export default router


