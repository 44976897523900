<template>
    <button
        v-if="!Object.keys(to || {}).length"
        class="button"
        :type="type"
        :disabled="disabled"
        :class="[`button--${theme}`, {'button--preloader' : preloader} ]"
        @click="$emit('click')"
    >
        <Preloader :color="'white'" v-if="preloader" :size="12" />
        <span v-if="caption">{{ caption }}</span>
    </button>
    <a :href="to"
        v-else-if="typeof to == 'string'"
        class="btn button"
        :class="[`button--${theme}`]"
    >
        <span v-if="caption"> {{ caption }}</span>
    </a>
    <localized-link
        v-else
        :to="to"
        :disabled="disabled"
        class="btn button"
        :class="[`button--${theme}`, {'button--preloader' : preloader} ]"
    >
        <Preloader :color="'white'" v-if="preloader" :size="12" />
        <span v-if="caption">{{ caption }}</span>
    </localized-link>
</template>

<script>
export default {
    name: "ButtonComponent",
    props: {
        caption: {
            required: false,
            type: String
        },
        to: {
            required: false,
            type: [Object, String]
        },
        theme: {
            required: false,
            type: String,
            default: "primary" // primary
        },
        type: {
            required: false,
            type: String,
            default: "button" // submit
        },
        disabled: {
            required: false,
            type: Boolean
        },
        preloader: {
            required: false,
            type: Boolean,
            default: false,
        }
    },

}
</script>

<style lang="scss" scoped>
    .button {
        border: none;
        border-radius: 0;
        color: white;
        text-align: center;
        font-family: $ff-primary;
        font-size: 16px;
        height: max-content;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        transition: 0.2s;
        font-weight: 400;
        padding: 10px 20px;
        max-width: 100%;
        text-align: center;

        &:hover {
            color: white;
        }

        &:focus {
            outline: none;
        }

        &--preloader {
            > span {
                visibility: hidden;
            }
        }

        &--primary {
            background-color: $blue;
            border: solid 1px $blue;
        }

        &--secondary {
            background-color: $yellow;
            border: solid 1px $yellow;
        }
    }

    [disabled] {
        cursor: default;
        background-color: $grey;
        border-color: $grey;
        color: white;
    }

</style>