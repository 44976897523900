<template>
    <language-switcher class="language-switcher" v-slot="{ links }" v-if="isLangsLoaded && !hasOnlyUa">
        <router-link
            :to="link.url" 
            v-for="link in links" 
            :key="link.langIndex"
            :class="['language-switcher__item', { 'language-switcher__item--active': link.activeClass } ]" 
            style="text-transform: uppercase"
        >
            {{ link.langName }}
        </router-link>
    </language-switcher>
</template>

<script>
export default {
    data() {
        return {
            hasOnlyUa: false,
            isLangsLoaded: false,
        }
    },

    mounted() {
        this.getLanguages()
    },

    methods: {
        getLanguages() {
            return this.api.global.languages().then((r) => {
                if (r.length < 2) { this.hasOnlyUa = true }
            }).finally(() => {
                this.isLangsLoaded = true
            })
        },
    }
}
</script>

<style lang="scss" scoped>
    .language-switcher {

        &__item {
            padding: 16px 7px;
            font-size: 17px;
            font-family: 'OpenSans';
            cursor: pointer;
            color: $black;
            transition: .2s;

            @media screen and (max-width: $lg) {
                color: white;

                &:last-child {
                    padding-right: 0;
                }
            }

            &--active {
                background-color: $yellow;

                @media screen and (max-width: $lg) {
                    background-color: transparent;
                    color: $yellow;
                }
            }

            &:hover {
                color: $black;
                background-color: $yellow;

                @media screen and (max-width: $lg) {
                    background-color: transparent;
                    color: $yellow;
                }
            }
        }
    }
</style>