<template>
    <div class="empty-list" :class="{ 'empty-list--small' : size == 'small' }">
        <span class="material-icons-outlined empty-list__icon">search_off</span>
        <p v-if="message" class="empty-list__message">{{ message || '' }}</p>
    </div>
</template>

<script>
export default {
    props: {
        message: {
            default: '',
            required: false
        },

        size: {
            default: '',
            required: false,
            type: String
        }
    },
}
</script>

<style lang="scss" scoped>
    .empty-list {

        $r: '.empty-list';

        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        padding-top: 100px;
        padding-bottom: 100px;
        flex-direction: column;

        &__icon {
            font-size: 100px;
            color: $lightgrey;
        }

        &__message {
            font-weight: bolder;
            font-size: 20px;
            color: $grey;
            margin-top: 0;
        }

        &--small {
            padding-top: 0;
            padding-bottom: 0;

            #{$r}__icon {
                font-size: 50px;
            }
        }
    }
</style>