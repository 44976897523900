import storage from './laravelStoragePath';
import api from "@/services/api";
import { i18n } from 'vue-lang-router';

export default {
    filters: {
        getDate(d) {
            if (!d) { return 'Recently' }
            let temp = new Date(d);
            let locale = i18n.locale == 'ua' ? 'uk' : 'en'
            let resultMonth = temp.toLocaleString(locale, { month: 'long' });
            let resultAll = `${resultMonth}, ${temp.getDate()}, ${temp.getFullYear()}`
            return resultAll
        }
    },

    data() {
        return {
            api: api,
            windowWidth: 0,
        }
    },

    computed: {
        isMobile() {
            return this.windowWidth < 992 ? true : false
        }
    },

    created() {
        window.addEventListener('resize', this.handleResize);
        this.handleResize();
    },

    methods: {
        storage: storage,
        handleResize() {
            this.windowWidth = window.innerWidth;
        }
    },

    destroyed() {
        window.removeEventListener('resize', this.handleResize);
    },
}