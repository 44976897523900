<template>
    <div class="preloader"
        :class="{
            'preloader--white' : color == 'white',
            'preloader--sm' : size <= 30
        }"
    >
        <div class="preloader__content" :style="{ width: `${size}px`, height: `${size}px` }"></div>
    </div>
</template>

<script>
export default {
    props: {
        size: {
            required: false,
            type: Number,
            default: 40
        },
        color: {
            required: false,
            type: String,
            default: 'black'
        }
    }
}
</script>

<style lang="scss" scoped>
    .preloader {
        $r: '.preloader';

        margin: 100px auto;
        width: 100%;
        display: flex;
        justify-content: center;

        &__content {
            display: inline-block;

            &::after {
                content: " ";
                display: block;
                width: 100%;
                height: 100%;
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%);
                margin: -6px;
                border-radius: 50%;
                border: 6px solid $black;
                border-color: $black transparent $black transparent;
                animation: preloader 1.2s linear infinite;
            }
        }

        &--white {
            #{$r}__content {
                &::after {
                    border: 6px solid $light;
                    border-color: $light transparent $light transparent;
                }
            }
        }

        &--sm {
            margin: 0 auto;
            position: absolute;

            #{$r}__content {
                &::after {
                    margin: -3px;
                    border-width: 3px;
                }
            }
        }
    }

    @keyframes preloader {
        0% {
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(360deg);
        }
    }
    
</style>