import api from '../_httpClient'

export default {
    view(postName) {
        return api.get(`/api/post/${postName}`)
    },

    tour(uri) {
        return api.get(`/api/tour3d/${uri}`)
    },

    all(chapterName, page) {
        return api.get(`/api/page/${chapterName}`, {params: { page }})
    },
} 