<template>
    <div class="upper-header">
        <div class="container">
            <div class="upper-header__content">
                <div class="upper-header__date">
                    <p>{{ date }}</p>
                </div>
                <div class="upper-header__menu">
                    <!-- <localized-link 
                        to="#" 
                        class="upper-header__item"
                        active-class="upper-header__item--active"
                        exact
                    >
                        {{ $t('components.UpperHeader.login') }}
                    </localized-link> -->
                    <localized-link
                        :to="{ name: 'Feedback' }" 
                        class="upper-header__item" 
                        active-class="upper-header__item--active"
                        exact
                    >
                        {{ $t('components.UpperHeader.feedback') }}
                    </localized-link>
                    <localized-link 
                        :to="{ name: 'Concept' }"
                        class="upper-header__item"
                        active-class="upper-header__item--active"
                        exact
                    >
                        {{ $t('components.UpperHeader.concept') }}
                    </localized-link>
                    
                    <LanguageSwitcher />
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import LanguageSwitcher from "@/components/LanguageSwitcher";

export default {
    components: {
        LanguageSwitcher
    },
    data() {
        return {
            date: '',
            weekday: []
        }
    },

    created() {
        this.weekday = [
            this.$i18n.t('components.UpperHeader.Sunday'), 
            this.$i18n.t('components.UpperHeader.Monday'), 
            this.$i18n.t('components.UpperHeader.Tuesday'), 
            this.$i18n.t('components.UpperHeader.Wednesday'), 
            this.$i18n.t('components.UpperHeader.Thursday'), 
            this.$i18n.t('components.UpperHeader.Friday'), 
            this.$i18n.t('components.UpperHeader.Saturday')
        ];
    },
    
    mounted() {
       /*  this.date = weekday[new Date().getDay()] + `, ` + new Date().getDate() + new Date().toLocaleString('uk', { month: 'long' }) + new Date().getFullYear(); */
        this.date = this.weekday[new Date().getDay()] + ', ' + new Date().toLocaleDateString()
    },
}
</script>

<style lang="scss" scoped>
    .upper-header {
        background-color: $lightgrey;

        &__content {
            display: flex;
            align-items: center;
            justify-content: space-between;
        }

        &__menu {
            display: flex;
            align-items: center;
        }

        &__item {
            padding: 16px 7px;
            font-size: 17px;
            font-family: 'OpenSans';
            cursor: pointer;
            color: $black;
            transition: .2s;

            &--active {
                background-color: $yellow;
            }

            &:hover {
                color: $black;
                background-color: $yellow;
            }
        }
    }
</style>