import api from '../_httpClient'

export default {
    word(q) {
        return api.get(`/api/search`, { params: { q }})
    },

    index(p, pageNumber) {
        return api.post(`/api/search?page=${pageNumber}`, p)
    }
} 