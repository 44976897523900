<template>
    <div id="app">
        <div class="base-layout">
            <UpperHeader v-if="!isMobile" :key="upperHeaderKey"/>
            <router-view />

            <div class="base-layout__up" @click="goUp">
                <span class="material-icons-round base-layout__upArrow">keyboard_arrow_up</span>
            </div>

            <div class="base-layout__toasts"></div>
        </div>
    </div>
</template>

<script>
import UpperHeader from "@/components/UpperHeader"

export default {
    components: {
        UpperHeader
    },

    data() {
        return {
            upperHeaderKey: 0,
        }
    },

    mounted() {
        window.addEventListener('scroll', this.handleScroll);
    },

    watch: {
        '$i18n.locale': function(newVal) {
            this.upperHeaderKey += 1
        },

        $route() {
            this.upperHeaderKey += 1
            let body = document.querySelector('body');
            body.style['overflow-y'] = 'auto' 
        }
    },

    methods: {
        goUp() {
            window.scrollTo({ top: 0, behavior: "smooth" });
        },

        handleScroll() {
            let toTopBtn = document.querySelector('.base-layout__up');
            if (window.pageYOffset > 800) {
                toTopBtn.style.opacity = 1
            } else {
                toTopBtn.style.opacity = 0
            }
        }
    },

    beforeDestroy() {
        window.removeEventListener('scroll', this.handleScroll);
    },
}
</script>

<style lang="scss" scoped>
    .base-layout {
        min-height: 100vh;
        display: flex;
        flex-direction: column; 
        position: relative;
        overflow-x: hidden;

        &__up {
            position: fixed;
            bottom: 20px;
            right: 20px;
            border-radius: 50%;
            background-color: $blue;
            padding: 3px;
            display: flex;
            align-items: center;
            justify-content: center;
            width: max-content;
            cursor: pointer;
            margin: auto auto 0;
            transition: .2s;
            opacity: 0;
        }

        &__upArrow {
            color: white;
            font-size: 22px;
            margin: 7px;
        }

        &__toasts {
            position: fixed;
            right: 100px;
            top: 80px;
            width: 500px;
            max-width: calc(100% - 40px);

            @media screen and (max-width: $lg) {
                top: 80px;
                right: 20px;
            }
        }

    }
</style>
