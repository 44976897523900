import api from '../_httpClient'

export default {
    slider() {
        return api.get(`/api/home/slider`)
    },

    categories() {
        return api.get(`/api/home/posts`)
    }
} 